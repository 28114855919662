import Modules from "./modules";
import VideoSupport from "../app/useful/video-support";

/**
 * This class adds the js functionality to the video module
 */
class Video {
  /**
   * Video constructor
   * @param {HTMLElement} module
   */
  constructor(module) {
    new VideoSupport(module);
  }

  /**
   * Returns the class name
   * @return {string}
   */
  static getName() {
    return "js-video";
  }
}

new Modules(Video);
